
export const permission = {
    allowedAdminOperationRole(roles) {
        let AllowedRoles = [
            'operation',
            'vision_admin',
            'developer',
        ];
        return AllowedRoles.some(role => roles.includes(role));  
    },
    allowedDeveloperRole(roles) {
        return roles.includes('developer');
    }
}