export default {
  action:{
    login:"Log In",
    logout:"Log Out",
    return: "Return to Main Page",
  },
  api:{
    response:{
      no_data:"No data.",
    },
  },
  model:{
    prop:{
      email:"Email Address",
      password:"Password",
    },
  },
  view:{
    PageErrorNotFound: {
      description: "Page not found.",
      title: "Oops...",
    },
    PageErrorSessionExpired: {
      description: "Please log in again.",
      title: "Your session has expired.",
    },
  },
}