export default{
    state:{
        user:null,
        
    },
    getters:{
        getUser(state){
            return state.user;
        }
    },
    mutations : {
        updateUser(state,user)
        {
            return state.user = user;
        }
    },
    actions:{
        clearUser(context)
        {
            context.commit("updateUser",null);
        }
    }
}