
import axios from 'axios';
export default {
    state: {
        totalNotification:0,
        totalTask:0,
        currentTitle:"Dashboard",
    },
    getters: {
        getTotalTask(state){
            return state.totalTask;
        },
        getTotalNotification(state){
            return state.totalNotification;
        },
        getCurrentTitle:state=>state.currentTitle,
    },
    actions: {
        fetchCurrentTotalNotification(context,id){
            axios.get(process.env.VUE_APP_SERVER_API+"/notification/total/unacknowledge/"+id).then(response=>{
                context.commit('updateTotalNotification',response.data.data);
            });
        },  
        fetchCurrentTotalTask(context,id){
            axios.get(process.env.VUE_APP_SERVER_API+"/task/total/id/"+id).then(response=>{
                context.commit('updateTotalTask',response.data.data);
            })  
        },
        clearTotalNotification(context){
            context.commit("updateTotalNotification",null);
        }
    },
    mutations: {
      updateTotalNotification(state,totalNotification){
        return state.totalNotification = totalNotification;
      },
      updateCurrentTitle(state,currentTitle){
        return state.currentTitle = currentTitle;
      },
      updateTotalTask(state,totalTask){
        return state.totalTask = totalTask;
      }
    }
  }