export default{
    state:{
        action:'null',
        prevUrl :'null',
    },
    getters:{
        getAction(state)
        {
            return state.action;
        },
        getPrevUrl(state)
        {
            return state.prevUrl;
        },
    },
    mutations:{
        updateAction(state,action)
        {
            return state.action = action;
        },
        updatePrevUrl(state,prevUrl)
        {
            return state.prevUrl = prevUrl;
        }
    },
    actions: {
        clearAction(context)
        {
            context.commit("updateAction",null);
        },
        clearPrevUrl(context){
            context.commit("updatePrevUrl",null);
        }
    }
}