export default {
  state: {
    token: null,
    fname:null,
    lname:null,
    id:null,
    email:null,
    role:null,
  },
  getters: {
    getApiToken(state){
      return state.token;
    },
    getFname(state){
      return state.fname;
    },
    getLname(state){
      return state.lname
    },
    getRole(state){
      return state.role;
    },
    getEmail(state){
      return state.email;
    },
    getUserId(state){
      return state.id;
    }
  },
  actions: {
    logout(context) {
      context.commit("updateAuth", null);
      context.commit("updateRole",null);
      context.commit("updateEmail",null);
      context.commit("updateUserId",null);
    },
    login(context,user){
      context.commit("updateAuth",user["api_token"]);
      context.commit("updateRole",user["role"]);
      context.commit("updateEmail",user["email"]);
      context.commit("updateUserId",user["id"]);
      context.commit("updateFname",user['fname']);
      context.commit("updateLname",user["lname"]);
    }
  },
  mutations: {
    updateAuth(state, token) {
      return state.token = token
    },
    updateRole(state,role)
    {
      return state.role = role;
    },
    updateFname(state,fname){
      return state.fname = fname;
    },
    updateLname(state,lname){
      return state.lname = lname;
    },
    updateEmail(state,email)
    {
      return state.email = email;
    },
    updateUserId(state,id)
    {
      return state.id = id;
    }
  }
}