export default{
    state:{
        company:null,
        
    },
    getters:{
        getCompany(state){
            return state.company;
        }
    },
    mutations : {
        updateCompany(state,company)
        {
            return state.company = company;
        }
    },
    actions:{
        clearCompany(context)
        {
            context.commit("updateCompany",null);
        }
    }
}