<template>
    <v-dialog v-model="isDisplay" width="700" @click="$emit('close')" @click:outside="$emit('close')" style="">
        <ATaskComplete @close="reloadPage" v-if="isTaskCompleted"/>
        <v-card class="pa-3">
            <div class="d-flex justify-center">
            <p class="text-h5 font-weight-bold ma-4 pa-4">
                    My Pick-Up Task
                </p>
            </div>
            <div
                class="d-flex justify-end">
                <v-btn
                    v-if="!api.isLoading && dataTable!=null"
                    :disabled="!isTaskSelected"
                    color="primary"
                    class="my-4"
                    @click="updateMultipleTaskStatus">
                    Mark As Complete
                </v-btn>

            </div>
            <v-skeleton-loader v-if="api.isLoading" ref="skeleton" type="table">

            </v-skeleton-loader>
            <div class="d-flex justify-center" v-else>
                <v-data-table v-if="this.dataTable!=null" :items="this.dataTable" :headers="tableHeaders" dense outlined>
                    <template v-slot:item.company_name="{item}">
                        <router-link :to="{name:'PageCompaniesDetail',params:{'id':item.company_id}}">
                            {{ item.company_name }}
                        </router-link>
                    </template>
                    <template v-slot:item.task_action="{item}">
                        <div v-html="item.task_action">

                        </div>
                    </template>

                    <template v-slot:item.created_at="{item}">
                        {{ convertTimeZone(item.created_at) }}
                    </template>
                    <template
                            v-slot:item.task_status="{item}">
                            <v-checkbox
                                v-model="item.checkbox"
                                @change="updateCheckbox()">

                            </v-checkbox>
                        </template>

                    <!-- <template v-slot:item.task_status="{item}">
                        <div v-if="isLoadingUpdateStatus">
                            <v-progress-circular indeterminate color="primary">

                            </v-progress-circular>
                        </div>
                        <div v-else>
                            <v-checkbox v-if="item.task_status=='Done'" v-model="statusCheckedTrue" disabled>
    
                            </v-checkbox>

                        </div>
                        <v-checkbox v-if="item.task_status=='To do'"  v-model="statusCheckedFalse" @click="updateTaskStatus(item.task_id)"></v-checkbox>
                    </template> -->
                </v-data-table>
                <div class="mt-4 text-h6" v-else>
                    <div class="d-flex justify-center flex-row">
                        <span>
                            No Active Task Recorded 
    
                        </span>

                    </div>
                    <div class="d-flex justify-center flex-row my-4">
                        <v-btn color="primary" @click="redirectMyTask" >
                            Go To My Task
                        </v-btn>

                    </div>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import ATaskComplete from '../common/ATaskComplete.vue';


// import mavonEditor from 'mavon-editor';
export default {
    components:{
    ATaskComplete
},
    created(){
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getTaskById"){
                let tempDatas=[];
                resp.data.forEach(data=>{
                    let tempData={
                        "task_id": data.task_id,
                        "task_name": data.task_name,
                        "task_category": data.task_category,
                        "task_action": data.task_action,
                        "task_status": data.task_status,
                        "company_name": data.company_name,
                        "company_id": data.company_id,
                        "created_at": data.created_at,
                        "checkbox":data.task_status=="To do"?false:true,

                    }
                    tempDatas.push(tempData);
                })
                this.dataTable = tempDatas;
            }
            if(resp.class=="updateMultipleTaskStatus"){
                let tempCurrentTask = this.$_.clone(this.$store.getters.getCurrentTask);
                let tempCurrentUpdateId = this.$_.clone(this.currentUpdateId);
                tempCurrentUpdateId.forEach(item=>{
                    tempCurrentTask.splice(tempCurrentTask.indexOf(item),1);
                })
                this.statusCheckedFalse = false;
                this.isLoadingUpdateStatus = false;
                this.$store.commit("updateCurrentTask",tempCurrentTask);
                this.isTaskCompleted = true;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }
        
    },
    mounted(){
        
        this.fetch();
    },
    data:()=>({
        currentUpdateId:null,
        isLoadingUpdateStatus:false,
        isTaskCompleted:false,
        isTaskSelected:false,
        statusCheckedTrue :true,
        statusCheckedFalse :false,
        dataTable:null,
        tableHeaders:[
            {
                text:'Company',
                value:'company_name',
            },
            {
                text:'Action',
                value:'task_category',
            },
            {
                text:'Description',
                value:'task_action',
            },
            // {
            //     text:'Id',
            //     value:'task_name',
            // },
            {
                text:'Date',
                value:'created_at'
            },
            {
                text:'Done',
                value:'task_status'
            },
           
        ],
        isDisplay:true,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    methods:{
        fetch(){
            let fetchActiveTaskApi = this.fetchActiveTask();
            this.$api.fetch(fetchActiveTaskApi);
        },
        fetchActiveTask(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/multiple";
            let formData = new FormData;
            let tempCurrentTask = (this.$store.getters.getCurrentTask!=null)?this.$store.getters.getCurrentTask:[]
            // let currentTask = (this.$store.getteres.getCurrentTask!=null)?this.$store.getters.getCurrentTask:[];
            tempCurrentTask.forEach(task=>{
                formData.append('id[]',task);
            })
            tempApi.params = formData;
           
            return tempApi;
        },
        updateMultipleTaskStatus(){
            let tempCurrentUpdateId = [];
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/multiple/status";
            let formData = new FormData;
            formData.append('userId',this.$store.getters.getUserId);
            formData.append('status','Done');
            this.dataTable.forEach(task=>{
                if(task.checkbox===true){
                    formData.append('tasks[]',task.task_id);
                    tempCurrentUpdateId.push(task.task_id);
                }
            })
            this.currentUpdateId = tempCurrentUpdateId;
            tempApi.params = formData;
            this.$api.fetch(tempApi);
        },
        updateTaskStatus(id){
            this.currentUpdateId = id;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/status/id/"+id;
            tempApi.params={
                'status':'Done',
                'userId':this.$store.getters.getUserId,
            };
            this.isLoadingUpdateStatus = true;
            this.$axios.all([
                this.$api.fetch(tempApi),
                this.fetch(),
            ])
        },
        updateCheckbox(){
            this.isTaskSelected = false;
            this.dataTable.forEach(item=>{
                if(item.checkbox===true){
                    this.isTaskSelected = true;
                }
            })
        },
        reloadPage(){
            location.reload();
        },
        redirectMyTask(){
            this.$emit("close");
            this.$router.push({name:'PageTaskListUser',params:{id:this.$store.getters.getUserId}});
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL');
        }
      
    }
}
</script>