import Vue from 'vue'
import Vuex from 'vuex'
//BOC
import auth from './modules/auth'
import company from './modules/company';
import user from './modules/user';
import action from './modules/action';
import staff from './modules/staff';
import team from './modules/team';
import form from './modules/form';
import notification from './modules/notification';
import task from './modules/task';
import features from './modules/features';
import filters from './modules/filter';
//EOC

Vue.use(Vuex)
const debug = process.env.VUE_APP_ENV !== 'production'

//BOC:[persist]
import VuexPersistence from 'vuex-persist'
import Cookies from 'js-cookie'
const vuexCookie = new VuexPersistence({
	key:"cookieStore",
	restoreState: (key) => Cookies.get(key),
	saveState: (key, state) =>
		Cookies.set(key, state, {
			expires: 30, // expire in 30 days
		}),
	modules:[
		//
	],
});

//BOC : Vuex persistence state, to make sure that the data is 
// still available when page refresh
const vuexLocal = new VuexPersistence({
	key:"localStore",
	storage: window.localStorage,
modules:[
		"auth","company",'user','action','staff','team','form','notification','task','features','filters'
	],
})
//EOC

//BOC: Vuex store declaration, all the Vuex in store folder will be saved here
export default new Vuex.Store({
	modules: {
		auth:auth,
		company:company,
		user:user,
		action:action,
		staff:staff,
		team:team,
		task:task,
		form:form,
		features:features,
		notification:notification,
		filters:filters,
	},
	strict: debug,
	plugins: [
		vuexCookie.plugin, 
		vuexLocal.plugin,
	],
})
//EOC
