export default{
    state:{
        salesperson:null,
    },
    getters:{
        getSalesperson(state){
            return state.salesperson;
        }
    },
    mutations:{
        updateSalesperson(state,salesperson){
            return state.salesperson = salesperson;
        }
    },
    actions:{
        clearSalesperson(context)
        {
            context.commit("updateSalesperson",null);
        }
    }
}