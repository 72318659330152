<style scoped>
/* .container{
    position:fixed;
    bottom:20px;
    left:93%;
    z-index: 9999;
} */
.v-overlay{
    z-index:9999
}
</style>

<template>
    <v-container>
        <ComponentDetailActiveTask @close="closeActiveTask" @click:outside="closeActiveTask" v-if="isDisplayTask"/>
        <v-btn rounded @click="()=>{ 
            this.isDisplayTask = true;
        }" color="primary"  style="position:fixed;bottom: 20px; left:94%;z-index: 9990;">
            <v-icon >
                bi-clipboard-check
            </v-icon>
        </v-btn>
    </v-container>
</template>
<script>
import ComponentDetailActiveTask from './ComponentDetailActiveTask.vue';

export default {
    data: () => ({
        isDisplayTask:false,
        currentTask: null,
    }),
    components: { ComponentDetailActiveTask },
    methods:{
        closeActiveTask(){
            this.isDisplayTask = false;
        }
    }
}
</script>