<template>
    <v-container
        class="px-0">
        <v-row>
            <v-col
                align="start"
                justify="start">
                <v-btn
                    @click="redirectBack"
                    color="primary">
                    <v-icon>
                        mdi-arrow-left-bold
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

export default {
data () {
    return {

    }
},
methods: {
    redirectBack() {
        this.$router.go(-1);
    }
}
}
</script>