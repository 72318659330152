<template>
    <span   
        v-if="checkFormat()">
        {{ this.formatDate() }}
    </span>
    <span
        v-else>
        -
    </span>
</template>
<script>

export default {
    props : ['date'],
    methods: {
        formatDate () {
            return this.$moment(this.date).format("LLL");
        },
        checkFormat() {
            let tempDate = this.$moment(this.date);
            if(tempDate.isValid()) {
                return true;
            }
            return false;
        }
    }
}
</script>