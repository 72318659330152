export default{
    state: {
        currentTask:[],
    },
    getters:{
        getCurrentTask(state){
            return state.currentTask;
        },
    },
    actions:{
        clearActiveTask(context){
            context.commit("updateCurrentTask",null);
        }
    },
    mutations:{
        updateCurrentTask(state,currentTask){
            return state.currentTask = currentTask;
        },
        
    },
}