<template>
    <transition name="fade">
        <div
        v-if="api">
        <v-alert
        elevation="6"
        type="success">
        {{ message }}
        </v-alert>
        <!-- <v-card
            class="mx-auto">
            <v-img
            src="/images/taxpod_vision_logo.png"
            height="90px"
            ></v-img>
            <hr/>
            <v-card-title
            class="d-flex justify-center text-h4 mt-6">
            Congratulations
            <v-icon
            color="primary">
                mdi-creation
            </v-icon>
            </v-card-title>
            <v-card-subtitle
            class="d-flex justify-center">
            Succesfully setting up your password
            </v-card-subtitle>
            <v-card-actions
            class="d-flex justify-center">
                <v-btn
                color="primary"
                href="/home">
                Go to home page
                </v-btn>
            </v-card-actions>
        </v-card> -->
        </div>
    </transition>
</template>

<script>
    export default {
        props:['api','message'],
        mounted()
        {
        }
    }

</script>