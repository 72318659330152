<template>
    <v-dialog v-model="notAllowed" height="700" width="700" @click:outside="$emit('close')">
        <v-card class="pa-3">
            <div class="d-flex justify-center">
                <v-img src="/images/robot.svg" contain max-width="150px" max-height="150px">

                </v-img>
            </div>
            <v-card-text class="py-4">
                <div class="d-flex justify-center font-weight-bold text-h4">
                    Denied
                </div>
                <div class="d-flex justify-center align-center text-center my-2 text-h6">
                    Sorry, but you are not allowed to access this section.
                </div>
                <div class="d-flex justify-center">
                    <v-btn color="primary" @click="$emit('close')" >
                        Okay
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

export default{
    props:['notAllowed'],
    methods:{
       
    },
}
</script>