export default{
    state:{
        team:null,
        
    },
    getters:{
        getTeam(state){
            return state.team;
        }
    },
    mutations : {
        updateTeam(state,team)
        {
            return state.team = team;
        }
    },
    actions:{
        clearTeam(context)
        {
            context.commit("updateTeam",null);
        }
    }
}