<template>
    <v-alert
    type="warning">
        {{message }}
    </v-alert>
</template>
<script>
export default{
    props:['message']
}
</script>