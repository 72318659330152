export default {
 
    state : {
        companyReport :{
            headers:"all",
            value:"",
            isFiltering:false,
        }
    },
    mutations : {
        setFilterCompanyReport (state,filter) {
            state.companyReport = filter;
        },
    },
    actions :  {
        updateFilterCompanyReport(context,filter) {
            context.commit('setFilterCompanyReport',filter);
        },
    },
    getters : {
        getFilterCompanyReport(state)  {
            return state.companyReport;
        }
    }
}