<template>
    <v-dialog v-model="isShow" width="700" @click:outside="$emit('cancel')">
        <v-card class="pa-3">
            <v-card-text class="py-4">
                <div class="d-flex justify-center">
                    <v-icon size="150" class="py-3" color="warning">
                        fa-exclamation-circle
                    </v-icon>
                </div>
                <div class="d-flex mt-3 justify-center align-center font-weight-bold text-h4 ">
                    Are you confirm to proceed ?
                </div>
                <div class="d-flex mt-3 justify-center align-center text-h6 ">
                    You won't be able to revert the changes
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                    <div class="d-flex justify-center">
                        <v-btn color="primary" size="100" plain @click=" $emit('cancel')">
                            Cancel
                        </v-btn>
                        <v-btn color="primary" @click="$emit('confirm')">
                            Confirm
                        </v-btn>
                    </div>
                </v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default{
        props:[
            'isShow',
        ],
        data:()=>({
        }),
        methods:{
            
        }
    }
</script>