<template>
    <v-container
        class="pa-0">
            <p
                v-if="this.text != null"
                class="copy-cursor"
                @click="copyText">
                {{ this.text }}
            </p>
            <p
                v-else>
                -
            </p>
            <v-snackbar
                v-model="showPopup"
                :timeout="timeout"
                color="success"
                top
                center
                >
                {{ this.message }}
            </v-snackbar>
    </v-container>
</template>
<script>
export default {
    props : ['text'],
    components: {
    },
    methods : {
        copyText() {
            navigator.clipboard.writeText(this.text);
            this.showPopup = true;
        },
        handleClosePopup() {
            this.showPopup = false;
        }
    },
    data(){
        return {
            showPopup:false,
            message:"Text copied succesfully",
            timeout:2000,
        }
    }

}

</script>
<style>
.copy-cursor {
  cursor: default;
}
.copy-cursor:hover {
    cursor:copy;
}
</style>