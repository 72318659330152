<style scoped>
* {
  padding: 0;
  margin: 0;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  /* width: 56px; */
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #7ac142;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 0px #7ac142;
  }
}

</style>

<template>
    <v-dialog v-model="isShow" height="700" width="700"  @click:outside="$emit('close')">
        <v-card class="pa-3">
            <div class="d-flex justify-center">
            
                <div class="wrapper"> <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                </div>
            </div>
            
            <div class="d-flex justify-center  mt-5">
                <div class="flex-column">
                    <div class="d-flex justify-center">
                        <p class="green--text " style="font-size: 2.125rem;;letter-spacing: 10px;word-wrap:break-word">
                            Awesome
                        </p>

                    </div>
                    <div class="d-flex justify-center">
                        <small class="green--text" style="font-size:16px">
                            Wow. You're doing fantastic. Don't forget to take a moment to relax.
                        </small>
                    </div>
                    <div class="d-flex justify-center pt-5 mt-5">
                        <v-btn color="primary " dense  @click="$emit('close')">
                            Continue
                        </v-btn>
                    </div>                   
                </div>
            </div>
            <div class="d-flex justify-center mt-2">
            </div>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    data: ()=>({
        isShow:true,
    }),
    methods:{
        redirectBack(){
            this.$router.go(-1)
        }
    }
}
</script>