<style scoped>

</style>

<template>
  <v-app style="background-color:#F2F3F7;" >
    <ComponentActiveTask/>
    <Sidebar />
      <v-app-bar
      app
      flat
      style="background-color:#F2F3F7;margin-left:0px;margin-right:0px"
      hide-overlay
      >
      <v-toolbar-title class="font-weight-bold">
        <!-- {{ this.$store.getters.getCurrentTitle }}    -->
      </v-toolbar-title>
      <v-spacer ></v-spacer>
      <div>
        <v-btn elevation="0" class="px-0" :to="{name:'PageTaskListUser',params:{id:this.$store.getters.getUserId}}">
          <v-icon color="primary">
            bi-clipboard-check
          </v-icon>
          <v-chip color="primary white--text">
            <span >
              {{ this.notification.totalTask }}
            </span>
          </v-chip>
        </v-btn>
      </div>
        <div style="margin-right:10px">
          <v-btn class="px-3" style="background-color:#F2F3F7;" @click="redirectNotification" elevation="0" >
            <v-icon  color="primary">
              mdi-bell-outline
            </v-icon>
            <v-chip color="primary white--text">
              {{ this.notification.totalNotification }}
            </v-chip>
          </v-btn>
          <!-- <v-btn color="primary" text @click="actions('PageAdminLogout')" class="ml-">
            My profile <v-icon color="primary">
              bi-chevron-down
            </v-icon>
          </v-btn> -->
          <v-menu offset-y class="px-4" >
          <template v-slot:activator="{on,attrs}">
            <v-btn  text elevation="0" color="primary"  class="pa-0 mx-3" v-bind="attrs" v-on="on">
                <span style="{font-size:12px}" class="font-weight-bold">
                  My Profile
                </span>
                <v-icon color="primary">
                  bi-chevron-down
                </v-icon>
            </v-btn>
          </template>
          <div class="">
            <v-card height="auto" width="auto" class="pa-5">
              <div class="d-flex justify-center">
                <v-avatar style="background: #E3E3E3;">
                  <span class="font-weight-bold primary--text"  >
                      {{ (this.$store.getters.getFname.toString().charAt(0).toUpperCase()) }} {{ (this.$store.getters.getLname.toString().charAt(0).toUpperCase()) }}
                  </span>
                </v-avatar>
              </div>

              <div class="d-flex justify-center mt-4">
                <span class="font-weight-bold" style="font-size:20px">
                  {{ this.$store.getters.getFname }} {{ this.$store.getters.getLname }}
                </span>
              </div>

              <div class="d-flex justify-center mb-5" >
                <span  style="font-size:16px;color: #767374;">
                  {{ this.$store.getters.getEmail}}
                </span>
              </div>

              <div class="d-flex justify-space-between mt-5">
                <v-btn outlined @click="redirectStaffDetail" class="black--text mx-4">
                  Profile
                </v-btn>
                <v-btn  outlined @click="redirectLogout" class="black--text mx-4">
                  Logout
                </v-btn>
              </div>
            </v-card>
          </div>
          <!-- <v-list color="primary">
            <div v-for="(item,index) in menus" :key="index">
              <v-list-item @click="actions(item.action)">
                <div class="white--text">
                  {{ item.text }}
                </div>
              </v-list-item>
            </div>
          </v-list> -->
        </v-menu>
        </div>

      </v-app-bar>
  
      <v-main style="background-color:#F2F3F7; padding: 40px 0px 0px 256px;">
          <div style="padding-left:2%;padding-right:2%">

            <router-view
            style="background-color: #F2F3F7;"
            :key="this.$router.history.current.path"/>
          </div>
      </v-main>
  </v-app>
</template>
<script>
import {mapState} from 'vuex'
// import SalespersonAction from '../salesperson/SalespersonAction.vue';
// import AdminAction from '../admin/AdminAction.vue';
// import OperationAction from '../operation/OperationAction.vue';
// import SalesManagerAction from '../salesperson/SalesManagerAction.vue'
// import SalesTeamLeadAction from '../salesperson/SalesTeamLeadAction.vue'
import Sidebar from '../dashboard/Sidebar.vue';
import ComponentActiveTask from '../task/ComponentActiveTask.vue';
// import Sidebar from '../dashboard/Sidebar.vue';
  export default {
    components:{
    // AdminAction,
    // SalespersonAction,
    // OperationAction,
    // SalesManagerAction,
    // SalesTeamLeadAction,
    Sidebar,
    ComponentActiveTask
},
    computed:mapState({
      notification:state  =>state.notification,
      countAlias :'notification',
    }),
    props:[
      //
    ],
    watch:{
      
    },
    data: () => ({
      menus:[
        {
          text:'Profile',
          action:'PageStaffDetail',
        },
        {
          text:'Logout',
          action:'PageAdminLogout',
        },
      
      ],
      role:null,
      api:{
        isSucessful:false,
        isLoading :false,
        isError:false,
        error:null,
        url:null,
        },
    }),
    created() {
      this.role = this.$store.getters.getRole
      this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = () => {
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }
    },
    mounted() {
      this.fetch()
    },
    methods: {
      fetch(){
        this.$store.dispatch('clearTotalNotification');
        this.$store.dispatch('fetchCurrentTotalNotification',this.$store.getters.getUserId);
        this.$store.dispatch('fetchCurrentTotalTask',this.$store.getters.getUserId);
        this.$store.dispatch('fetchFeatures');
      },
      fetchTotalNotification(){
        let tempApi = this.$_.clone(this.api);
        tempApi.method = "GET";
        tempApi.url = process.env.VUE_APP_SERVER_API+"/notification/total/user/"+this.$store.getters.getEmail;
        return tempApi;
      },
      redirectNotification(){
        this.$router.push({name:'PageNotificationList'});
      },
      redirectDashboard(){
        this.$router.push({name:'PageHomeDashboard'});
      },
      redirectLogout(){
        this.$router.push({name:'PageAdminLogout'});
      },
      redirectStaffDetail(){
        this.$router.push({name:'PageStaffDetail',params:{id:this.$store.getters.getUserId}});
      },
      actions(action){
        if(action=='PageStaffDetail'){
          this.$router.push({name:action,params:{'id':this.$store.getters.getUserId}})
          return 1;
        }
        this.$router.push({name:action});
      }
    }
  }
</script>