export default{
    state:{
        form:null,
        
    },
    getters:{
        getForm(state){
            return state.form;
        }
    },
    mutations : {
        updateForm(state,form)
        {
            return state.form = form;
        }
    },
    actions:{
        clearForm(context)
        {
            context.commit("updateForm",null);
        }
    }
}